/** @jsx jsx */
import { jsx, Box, Container, Divider, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  HeroGradient,
  HighlightedServices,
  PatientStoryGrid,
  SectionHeading,
  ServicesDirectory,
  CentersOfExcellenceLabels,
  Units,
} from "../../components/elements"

const SpecialtyCare = ({ data, pageContext }) => {
  const page = data.datoCmsSpecialtyCare
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container sx={{ px: "1em", py: [6, 6, 7], position: 'relative' }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
            </HeroGradient>
          </BackgroundImage>
            <ColorBar sx={{ position: "absolute", bottom: 0, left: 0, zIndex: 20 }} />
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page.introContent}</Styled.h6>
              </Box>
              <Text
                variant="styles.twoCols"
                sx={{ width: ["full"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>
          </Container>
        </Section>

        {/* Services Directory */}
        <Box as="section" id="directory" sx={{ pb: 5 }}>
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ width: ["full"], textAlign: "center" }}>
                <SectionHeading>Services Directory</SectionHeading>
              </Box>

              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <ServicesDirectory />
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* Units & Centers of Excellence */}
        <Section>
          <Container sx={{ px: "1em" }}>
            <CentersOfExcellenceLabels />

            <Units />
          </Container>
        </Section>

        {/* Highlighted Services */}
        <Box as="section" id="highlighted">
          <Container sx={{ px: "1em" }}>
            <Divider sx={{ mx: 0 }} />
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ pt: 4, textAlign: "center", width: ["full"] }}>
                <SectionHeading>Highlighted Services</SectionHeading>
              </Box>

              <Box sx={{ width: ["full"] }}>
                <HighlightedServices />
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* Patient Stories */}
        <Section id="patients-stories" sx={{ backgroundColor: "warmgray" }}>
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ width: "full", textAlign: "center" }}>
                <PatientStoryGrid />
                <br />
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default SpecialtyCare

export const query = graphql`
  {
    datoCmsSpecialtyCare {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`
